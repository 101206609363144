<template>
  <v-col
    class="pt-2"
    cols="12"
    sm="8"
    offset-sm="2"
    lg="6"
    offset-lg="3"
    xl="4"
    offset-xl="4"
  >
    <v-card v-if="errorType === 'login'">
      <img
        src="../assets/notLoggedIn.gif"
        width="auto"
        alt=""
      >
      <v-card-title primary-title="">
        <div>
          <h3 class="text-h5 mb-0">
            You are not logged in!
          </h3>
          <div>Use the button below to login using your DarkCosmos account details.</div>
        </div>
      </v-card-title>
      <v-card-actions>
        <v-btn
          text
          color="primary"
          @click.native="requestLoginForm"
        >
          <v-icon left>
            mdi-account-outline
          </v-icon>
          <span>Login</span>
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-card v-else-if="errorType === 'access'">
      <img
        src="../assets/noAccess.gif"
        width="auto"
        alt=""
      >
      <v-card-title primary-title="">
        <div>
          <h3 class="text-h5 mb-0">
            Oh dear...
          </h3>
          <div>It seems you don't have access here, you can use the button below to switch to a different DarkCosmos account, or logout.</div>
        </div>
      </v-card-title>
      <v-card-actions>
        <v-btn
          text
          color="primary"
          @click.native="requestLoginForm"
        >
          <v-icon left>
            mdi-swap-horizontal
          </v-icon>
          <span>Switch User</span>
        </v-btn>
        <v-btn
          text
          color="secondary"
          @click.native="requestLogout"
        >
          <v-icon left>
            mdi-exit-to-app
          </v-icon>
          <span>Logout</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-col>
</template>

<script>
export default {
  name: 'UserErrorCard',
  props: {
    errorType: {
      type: String,
      required: true,
      default: 'unknown'
    }
  },
  methods: {
    requestLoginForm: function () {
      this.$emit('onLoginRequest')
    },
    requestLogout: function () {
      this.$emit('onLogoutRequest')
    }
  }
}
</script>

<style scoped>

</style>
